<!--
 * @Author: Akko
 * @Description: 基本资格
-->
<template>
    <div>
        <el-form ref="userForm" :model="userForm" :rules="rules" label-width="0">
            <!-- 主申请人 -->
            <div class="card-container">
                <div class="card-heard">主申请人</div>
                <div class="basics-box">
                    <div class="basics-title">1、是否曾在香港或其他地方因任何罪行或违法行为被定罪</div>
                    <div class="same-line">
                        <el-form-item prop="question.hasConviction.has">
                            <el-radio-group v-model="userForm.question.hasConviction.has" @change="$event==='N'?userForm.question.hasConviction.info='':''">
                                <el-radio label="N">否</el-radio>
                                <el-radio label="Y">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="userForm.question.hasConviction.has==='Y'" class="input-line" prop="question.hasConviction.info">
                            <el-input v-model.trim="userForm.question.hasConviction.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                        </el-form-item>
                    </div>
                </div>
                <div class="basics-box">
                    <div class="basics-title">2、是否曾在香港或其他地方被拒入境／递解／遣送或要求离境？</div>
                    <div class="same-line">
                        <el-form-item prop="question.hasDeparture.has">
                            <el-radio-group v-model="userForm.question.hasDeparture.has" @change="$event==='N'?userForm.question.hasDeparture.info='':''">
                                <el-radio label="N">否</el-radio>
                                <el-radio label="Y">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="userForm.question.hasDeparture.has==='Y'" class="input-line" prop="question.hasDeparture.info">
                            <el-input v-model.trim="userForm.question.hasDeparture.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                        </el-form-item>
                    </div>
                </div>
                <div class="basics-box">
                    <div class="basics-title">3、是否曾被拒绝签发香港或其他地方的签证／进入许可？</div>
                    <div class="same-line">
                        <el-form-item prop="question.hasDeny.has">
                            <el-radio-group v-model="userForm.question.hasDeny.has" @change="$event==='N'?userForm.question.hasDeny.info='':''">
                                <el-radio label="N">否</el-radio>
                                <el-radio label="Y">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="userForm.question.hasDeny.has==='Y'" class="input-line" prop="question.hasDeny.info">
                            <el-input v-model.trim="userForm.question.hasDeny.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                        </el-form-item>
                    </div>
                </div>
                <div class="basics-box">
                    <div class="basics-title">4、是否曾触犯香港或任何地方的入境法例？</div>
                    <div class="same-line">
                        <el-form-item prop="question.hasBreakingLaw.has">
                            <el-radio-group v-model="userForm.question.hasBreakingLaw.has" @change="$event==='N'?userForm.question.hasBreakingLaw.info='':''">
                                <el-radio label="N">否</el-radio>
                                <el-radio label="Y">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="userForm.question.hasBreakingLaw.has==='Y'" class="input-line" prop="question.hasBreakingLaw.info">
                            <el-input v-model.trim="userForm.question.hasBreakingLaw.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                        </el-form-item>
                    </div>
                </div>
                <div class="basics-box">
                    <div class="basics-title">5、是否曾经使用另一个姓名或身份申请香港入境签证/进入许可或进入香港？</div>
                    <div class="same-line">
                        <el-form-item prop="question.hasOtherId.has">
                            <el-radio-group v-model="userForm.question.hasOtherId.has" @change="$event==='N'?userForm.question.hasOtherId.info='':''">
                                <el-radio label="N">否</el-radio>
                                <el-radio label="Y">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="userForm.question.hasOtherId.has==='Y'" class="input-line" prop="question.hasOtherId.info">
                            <el-input v-model.trim="userForm.question.hasOtherId.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                        </el-form-item>
                    </div>
                </div>
                <div class="basics-box">
                    <div class="basics-title">6、是否办理过香港身份证？</div>
                    <div class="same-line">
                        <el-form-item prop="question.hasHkId.has">
                            <el-radio-group v-model="userForm.question.hasHkId.has" @change="$event==='N'?userForm.question.hasHkId.info='':''">
                                <el-radio label="N">否</el-radio>
                                <el-radio label="Y">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="userForm.question.hasHkId.has==='Y'" class="input-line" prop="question.hasHkId.info">
                            <el-input v-model.trim="userForm.question.hasHkId.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                        </el-form-item>
                    </div>
                </div>
            </div>

            <!-- 配偶 -->
            <div v-if="(marriedStatus==='1'||marriedStatus==='4')&&goingWithPartner" class="card-container">
                <div class="card-heard">配偶</div>
                <div class="basics-box">
                    <div class="basics-title">1、是否现正在香港?</div>
                    <div class="same-line">
                        <el-form-item prop="question.spouseInHk.has">
                            <el-radio-group v-model="userForm.question.spouseInHk.has" @change="$event==='N'?userForm.question.spouseInHk.info='':''">
                                <el-radio label="N">否</el-radio>
                                <el-radio label="Y">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="userForm.question.spouseInHk.has==='Y'" class="input-line" prop="question.spouseInHk.info">
                            <el-input v-model.trim="userForm.question.spouseInHk.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                        </el-form-item>
                    </div>
                </div>
                <div class="basics-box">
                    <div class="basics-title">2、是否曾被拒绝入境/递解/遣送或要求离开香港?</div>
                    <div class="same-line">
                        <el-form-item prop="question.spouseDeparture.has">
                            <el-radio-group v-model="userForm.question.spouseDeparture.has" @change="$event==='N'?userForm.question.spouseDeparture.info='':''">
                                <el-radio label="N">否</el-radio>
                                <el-radio label="Y">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="userForm.question.spouseDeparture.has==='Y'" class="input-line" prop="question.spouseDeparture.info">
                            <el-input v-model.trim="userForm.question.spouseDeparture.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                        </el-form-item>
                    </div>
                </div>
                <div class="basics-box">
                    <div class="basics-title">3、是否曾被拒绝签发签证／进入许可以入境香港？</div>
                    <div class="same-line">
                        <el-form-item prop="question.spouseDeny.has">
                            <el-radio-group v-model="userForm.question.spouseDeny.has" @change="$event==='N'?userForm.question.spouseDeny.info='':''">
                                <el-radio label="N">否</el-radio>
                                <el-radio label="Y">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="userForm.question.spouseDeny.has==='Y'" class="input-line" prop="question.spouseDeny.info">
                            <el-input v-model.trim="userForm.question.spouseDeny.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                        </el-form-item>
                    </div>
                </div>
                <div class="basics-box">
                    <div class="basics-title">4、是否办理过香港身份证？</div>
                    <div class="same-line">
                        <el-form-item prop="question.spouseHasHkId.has">
                            <el-radio-group v-model="userForm.question.spouseHasHkId.has" @change="$event==='N'?userForm.question.spouseHasHkId.info='':''">
                                <el-radio label="N">否</el-radio>
                                <el-radio label="Y">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="userForm.question.spouseHasHkId.has==='Y'" class="input-line" prop="question.spouseHasHkId.info">
                            <el-input v-model.trim="userForm.question.spouseHasHkId.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                        </el-form-item>
                    </div>
                </div>
            </div>

            <!-- 子女 -->
            <div v-for="(item,index) in userForm.question.childList" :key="index">
                <div class="card-container">
                    <div class="card-heard">子女 - {{ item.subsetName }}</div>
                    <div class="basics-box">
                        <div class="basics-title">1、是否现正在香港?</div>
                        <div class="same-line">
                            <el-form-item :prop="'question.childList.' + index + '.childInHk.has'" :rules="{required: true, message: '请选择', trigger: 'change'}">
                                <el-radio-group v-model="item.childInHk.has" @change="$event==='N'?item.childInHk.info='':''">
                                    <el-radio label="N">否</el-radio>
                                    <el-radio label="Y">是</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item v-if="item.childInHk.has==='Y'" class="input-line" :prop="'question.childList.' + index + '.childInHk.info'" :rules="{required: true, message: '请输入', trigger: 'blur'}">
                                <el-input v-model.trim="item.childInHk.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                            </el-form-item>
                        </div>
                    </div>
                    <div class="basics-box">
                        <div class="basics-title">2、是否曾被拒绝入境/递解/遣送或要求离开香港?</div>
                        <div class="same-line">
                            <el-form-item :prop="'question.childList.' + index + '.childDeparture.has'" :rules="{required: true, message: '请选择', trigger: 'change'}">
                                <el-radio-group v-model="item.childDeparture.has" @change="$event==='N'?item.childDeparture.info='':''">
                                    <el-radio label="N">否</el-radio>
                                    <el-radio label="Y">是</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item v-if="item.childDeparture.has==='Y'" class="input-line" :prop="'question.childList.' + index + '.childDeparture.info'" :rules="{required: true, message: '请输入', trigger: 'blur'}">
                                <el-input v-model.trim="item.childDeparture.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                            </el-form-item>
                        </div>
                    </div>
                    <div class="basics-box">
                        <div class="basics-title">3、是否曾被拒绝签发签证／进入许可以入境香港？</div>
                        <div class="same-line">
                            <el-form-item :prop="'question.childList.' + index + '.childDeny.has'" :rules="{required: true, message: '请选择', trigger: 'change'}">
                                <el-radio-group v-model="item.childDeny.has" @change="$event==='N'?item.childDeny.info='':''">
                                    <el-radio label="N">否</el-radio>
                                    <el-radio label="Y">是</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item v-if="item.childDeny.has==='Y'" class="input-line" :prop="'question.childList.' + index + '.childDeny.info'" :rules="{required: true, message: '请输入', trigger: 'blur'}">
                                <el-input v-model.trim="item.childDeny.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                            </el-form-item>
                        </div>
                    </div>
                    <div class="basics-box">
                        <div class="basics-title">4、是否为前次婚姻的子女？</div>
                        <div class="same-line">
                            <el-form-item :prop="'question.childList.' + index + '.childStepchild.has'" :rules="{required: true, message: '请选择', trigger: 'change'}">
                                <el-radio-group v-model="item.childStepchild.has" @change="$event==='N'?item.childStepchild.info='':''">
                                    <el-radio label="N">否</el-radio>
                                    <el-radio label="Y">是</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item v-if="item.childStepchild.has==='Y'" class="input-line" :prop="'question.childList.' + index + '.childStepchild.info'" :rules="{required: true, message: '请输入', trigger: 'blur'}">
                                <el-input v-model.trim="item.childStepchild.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                            </el-form-item>
                        </div>
                    </div>
                    <div class="basics-box">
                        <div class="basics-title">5、是否办理过香港身份证？</div>
                        <div class="same-line">
                            <el-form-item :prop="'question.childList.' + index + '.childHasHkId.has'" :rules="{required: true, message: '请选择', trigger: 'change'}">
                                <el-radio-group v-model="item.childHasHkId.has" @change="$event==='N'?item.childHasHkId.info='':''">
                                    <el-radio label="N">否</el-radio>
                                    <el-radio label="Y">是</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item v-if="item.childHasHkId.has==='Y'" class="input-line" :prop="'question.childList.' + index + '.childHasHkId.info'" :rules="{required: true, message: '请输入', trigger: 'blur'}">
                                <el-input v-model.trim="item.childHasHkId.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                            </el-form-item>
                        </div>
                    </div>
                    <!-- <div class="basics-box">
                        <div class="basics-title no-tip">4、是否需要英语培训 ?</div>
                        <div class="same-line">
                            <el-form-item>
                                <el-radio-group v-model="item.childEnglishTraining.has" @change="$event==='N'?item.childEnglishTraining.info='':''">
                                    <el-radio label="N">否</el-radio>
                                    <el-radio label="Y">是</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item v-if="item.childEnglishTraining.has==='Y'" class="input-line">
                                <el-input v-model.trim="item.childEnglishTraining.info" maxlength="200" placeholder="如是，日期及详细情况" clearable />
                            </el-form-item>
                        </div>
                    </div> -->
                </div>
            </div>

            <!-- 家庭背景 -->
            <div class="card-container">
                <div class="card-heard">家庭背景</div>
                <div class="basics-box">
                    <div class="basics-title">1、至少一名直系家庭成员（已婚配偶、父母、兄弟姊妹、子女）是现居于香港的香港永久性居民</div>
                    <el-form-item prop="question.backgroundMember.has">
                        <el-radio-group v-model="userForm.question.backgroundMember.has">
                            <el-radio label="Y">满足</el-radio>
                            <el-radio label="N">不满足</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <div class="basics-box">
                    <div class="basics-title no-tip">2、家庭收入</div>
                    <el-form-item>
                        <el-radio-group v-model="userForm.order.householdIncome">
                            <el-radio :label="1">50万以下</el-radio>
                            <el-radio :label="2">50-99万</el-radio>
                            <el-radio :label="3">100w以上</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
            </div>
        </el-form>

        <!-- 保存按钮 -->
        <div class="operations-group">
            <el-button class="person-btn" @click="goPre">上一步</el-button>
            <el-button v-if="(user.baseSchedule - 1) < 3" class="person-btn btn-blue" :loading="isSave" @click="saveForm('save')">保存</el-button>
            <el-button class="person-btn btn-blue" :loading="isNext" @click="saveForm('next')">下一步</el-button>
        </div>
    </div>
</template>

<script lang="javascript">
import { saveQuestion } from "@/api/workfow.js";
import { mapActions, mapState } from "vuex";
export default {
    name: "Basics",
    components: {},
    data() {
        // 判断是否合辑
        let judgeHasConviction = (rule, value, callback) => {
            if (
                this.userForm.question.hasConviction.has === "Y" &&
                value === ""
            ) {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        let judgeHasDeparture = (rule, value, callback) => {
            if (
                this.userForm.question.hasDeparture.has === "Y" &&
                value === ""
            ) {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        let judgeHasDeny = (rule, value, callback) => {
            if (this.userForm.question.hasDeny.has === "Y" && value === "") {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        let judgeHasBreakingLaw = (rule, value, callback) => {
            if (
                this.userForm.question.hasBreakingLaw.has === "Y" &&
                value === ""
            ) {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        let judgeHasOtherId = (rule, value, callback) => {
            if (this.userForm.question.hasOtherId.has === "Y" && value === "") {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        let judgeHasHKId = (rule, value, callback) => {
            if (this.userForm.question.hasHkId.has === "Y" && value === "") {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        let judgeSpouseInHk = (rule, value, callback) => {
            if (this.userForm.question.spouseInHk.has === "Y" && value === "") {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        let judgeSpouseDeparture = (rule, value, callback) => {
            if (
                this.userForm.question.spouseDeparture.has === "Y" &&
                value === ""
            ) {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        let judgeSpouseDeny = (rule, value, callback) => {
            if (this.userForm.question.spouseDeny.has === "Y" && value === "") {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        let judgeSpouseHasHkId = (rule, value, callback) => {
            if (
                this.userForm.question.spouseHasHkId.has === "Y" &&
                value === ""
            ) {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        var judgeChildInHk = (rule, value, callback) => {
            if (this.userForm.question.childInHk.has === "Y" && value === "") {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        let judgeChildDeparture = (rule, value, callback) => {
            if (
                this.userForm.question.childDeparture.has === "Y" &&
                value === ""
            ) {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        let judgeChildDeny = (rule, value, callback) => {
            if (this.userForm.question.childDeny.has === "Y" && value === "") {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        let judgeChildEnglishTraining = (rule, value, callback) => {
            if (this.userForm.question.childDeny.has === "Y" && value === "") {
                callback(new Error("请输入"));
            } else {
                callback();
            }
        };
        return {
            userForm: {
                option: "",
                question: {
                    orderId: 0,
                    hasConviction: {
                        //是否曾在香港或其他地方因任何罪行或违法行为被定罪
                        has: "",
                        info: "",
                    },
                    hasDeparture: {
                        //是否曾在香港或其他地方被拒入境／递解／遣送或要求离境？
                        has: "",
                        info: "",
                    },
                    hasDeny: {
                        //是否曾被拒绝签发香港或其他地方的签证／进入许可？
                        has: "",
                        info: "",
                    },
                    hasBreakingLaw: {
                        //是否曾触犯香港或任何地方的入境法例
                        has: "",
                        info: "",
                    },
                    hasOtherId: {
                        //是否曾经使用另一个姓名或身份申请香港入境签证
                        has: "",
                        info: "",
                    },
                    hasHkId: {
                        //是否办理过香港身份证
                        has: "",
                        info: "",
                    },
                    spouseInHk: {
                        //配偶-是否现正在香港
                        has: "",
                        info: "",
                    },
                    spouseDeparture: {
                        //配偶-是否曾被拒绝入境/递解/遣送或要求离开香港
                        has: "",
                        info: "",
                    },
                    spouseDeny: {
                        //配偶-是否曾被拒绝签发签证/进入许可以入境香港？
                        has: "",
                        info: "",
                    },
                    spouseHasHkId: {
                        //配偶-是否办理过香港身份证
                        has: "",
                        info: "",
                    },
                    childList: [
                        // {
                        //     id: "",
                        //     subsetName: "",
                        //     childInHk: {
                        //         //子女-是否现正在香港?
                        //         has: "",
                        //         info: "",
                        //     },
                        //     childDeparture: {
                        //         //子女-是否曾被拒绝入境/递解/遣送或要求离开香港?
                        //         has: "",
                        //         info: "",
                        //     },
                        //     childDeny: {
                        //         //子女-是否曾被拒绝签发签证/进入许可以入境香港？
                        //         has: "",
                        //         info: "",
                        //     },
                        //     childEnglishTraining: {
                        //         //子女-是否需要英语培训？
                        //         has: "",
                        //         info: "",
                        //     },
                        // },
                    ],
                    childInHk: {
                        //子女-是否现正在香港?
                        has: "",
                        info: "",
                    },
                    childDeparture: {
                        //子女-是否曾被拒绝入境/递解/遣送或要求离开香港?
                        has: "",
                        info: "",
                    },
                    childDeny: {
                        //子女-是否曾被拒绝签发签证/进入许可以入境香港？
                        has: "",
                        info: "",
                    },
                    childEnglishTraining: {
                        //子女-是否需要英语培训？
                        has: "",
                        info: "",
                    },
                    childStepchild: {
                        //子女-是否为前次婚姻的子女
                        has: "",
                        info: "",
                    },
                    childHasHkId: {
                        //子女-是否办理过香港身份证
                        has: "",
                        info: "",
                    },
                    backgroundMember: {
                        //背景-至少一名直系家庭成员（已婚配偶、父母、兄弟姊妹、子女）是现居于香港的香港永久性居民
                        has: "",
                        info: "",
                    },
                    backgroundFollow: {
                        //有随行已婚配偶，且配偶拥有学士或以上的学位证（仅毕业证，无学位证不算
                        has: "",
                        info: "",
                    },
                    backgroundAdult: {
                        //有随行的18岁以下子女
                        has: "",
                        info: "",
                    },
                },
                order: {
                    //订单id
                    id: 0,
                    householdIncome: 0, //家庭收入 1-50w以下 2-50-99w  3-100w以上
                },
            },
            rules: {
                "question.hasConviction.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "question.hasDeparture.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "question.hasDeny.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "question.hasBreakingLaw.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "question.hasOtherId.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "question.hasHkId.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "question.spouseInHk.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "question.spouseDeparture.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "question.spouseDeny.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "question.spouseHasHkId.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "question.backgroundMember.has": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "question.hasConviction.info": [
                    {
                        required: true,
                        validator: judgeHasConviction,
                        trigger: "blur",
                    },
                ],
                "question.hasDeparture.info": [
                    {
                        required: true,
                        validator: judgeHasDeparture,
                        trigger: "blur",
                    },
                ],
                "question.hasDeny.info": [
                    {
                        required: true,
                        validator: judgeHasDeny,
                        trigger: "blur",
                    },
                ],
                "question.hasBreakingLaw.info": [
                    {
                        required: true,
                        validator: judgeHasBreakingLaw,
                        trigger: "blur",
                    },
                ],
                "question.hasOtherId.info": [
                    {
                        required: true,
                        validator: judgeHasOtherId,
                        trigger: "blur",
                    },
                ],
                "question.hasHkId.info": [
                    {
                        required: true,
                        validator: judgeHasHKId,
                        trigger: "blur",
                    },
                ],
                "question.spouseInHk.info": [
                    {
                        required: true,
                        validator: judgeSpouseInHk,
                        trigger: "blur",
                    },
                ],
                "question.spouseDeparture.info": [
                    {
                        required: true,
                        validator: judgeSpouseDeparture,
                        trigger: "blur",
                    },
                ],
                "question.spouseDeny.info": [
                    {
                        required: true,
                        validator: judgeSpouseDeny,
                        trigger: "blur",
                    },
                ],
                "question.judgeSpouseHasHkId.info": [
                    {
                        required: true,
                        validator: judgeSpouseHasHkId,
                        trigger: "blur",
                    },
                ],
            },
            subsetList: [],
            isSave: false, //防抖loading
            marriedStatus: "1", // 婚姻状态 1=已婚,2=未婚,3=离婚,4=分居 5-丧偶
            goingWithPartner: false, //伴侣是否随行
            isNext: false,
        };
    },
    computed: {
        ...mapState(["user"])
    },
    created() {
        this.dataEcho();
    },
    methods: {
        ...mapActions(["getInfoData"]),
        // 数据回显
        dataEcho() {
            this.getInfoData(this.$route.params.id).then((res) => {
                if (res.code === 200) {
                    this.subsetList = res.data.subsetList;
                    this.userForm.question.orderId = this.$route.params.id;
                    this.userForm.question =
                        res.data.question === null
                            ? this.userForm.question
                            : res.data.question;
                    this.userForm.order.householdIncome =
                        res.data.order.householdIncome;
                    this.userForm.order.id = res.data.order.id;
                    this.marriedStatus = res.data.information.married;
                    res.data.subsetList.forEach((ele) => {
                        // 子女是否随行
                        if (ele.relation === "4" && ele.follow == 1) {
                            let haveRes = this.userForm.question.childList.find(
                                (item) => item.id === ele.id
                            );
                            if (haveRes === undefined) {
                                this.userForm.question.childList.push({
                                    id: ele.id,
                                    subsetName: ele.subsetName,
                                    childInHk: {
                                        //子女-是否现正在香港?
                                        has: "",
                                        info: "",
                                    },
                                    childDeparture: {
                                        //子女-是否曾被拒绝入境/递解/遣送或要求离开香港?
                                        has: "",
                                        info: "",
                                    },
                                    childDeny: {
                                        //子女-是否曾被拒绝签发签证/进入许可以入境香港？
                                        has: "",
                                        info: "",
                                    },
                                    childEnglishTraining: {
                                        //子女-是否需要英语培训？
                                        has: "",
                                        info: "",
                                    },
                                    childStepchild: {
                                        //子女-是否为前次婚姻的子女
                                        has: "",
                                        info: "",
                                    },
                                    childHasHkId: {
                                        //子女-是否办理过香港身份证
                                        has: "",
                                        info: "",
                                    },
                                });
                            }
                        }
                        // 伴侣是否随行
                        if (ele.relation === "3") {
                            this.goingWithPartner =
                                ele.follow === 2 ? false : true;
                        }
                    });
                }
            });
        },
        // 判断是保存还是下一步
        saveForm(txt) {
            localStorage.setItem("stepChange", 0);
            this.$store.commit("stepChange");
            if (txt === "save") {
                this.isSave = true;
                this.userForm.option = "save";
                return this.hanledSaveQuestion(3);
            } else {
                this.userForm.option = "submit";
                this.$refs.userForm.validate((valid) => {
                    if (valid) {
                        this.isNext = true;
                        return this.hanledSaveQuestion(4);
                    } else {
                        return this.$message.error("请完善信息");
                    }
                });
            }
        },
        // 发起保存动作
        hanledSaveQuestion(parmas) {
            this.userForm.question.backgroundFollow = {
                has: "",
                info: "",
            };
            this.userForm.question.backgroundAdult = {
                has: "",
                info: "",
            };
            return new Promise((resolve, reject) => {
                saveQuestion(this.userForm).then((res) => {
                    if (res.code === 200) {
                        // 切换步骤保存 下一步的时候才设置
                        if (this.userForm.option === "submit") {
                            this.saveUserData(parmas);
                            this.$message.success("提交成功！");
                            this.isNext = false;
                            this.$router.replace({
                                name: "step" + parmas,
                            });
                        } else {
                            this.$message.success("保存成功！");
                            this.dataEcho();
                            this.isSave = false;
                            this.isNext = false;
                        }
                        resolve(res);
                    } else {
                        reject(res);
                    }
                });
            });
        },
        // 返回上一步
        goPre() {
            this.$router.replace({
                name: "step2",
            });
        },
    },
};
</script>

<style lang="less" scoped>
.card-heard {
    margin-bottom: 25px;
}

.basics-box {
    padding: 0 24px;
    color: #606266;
    font-size: 14px;

    .basics-title {
        margin-bottom: 9px;
        position: relative;
        padding-left: 12px;

        &::before {
            content: "*";
            position: absolute;
            left: 0;
            top: 3px;
            color: red;
        }
    }

    .basics-title.no-tip {
        padding-left: 0;

        &::before {
            content: "";
        }
    }
}

.same-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 47px;

    .el-radio {
        margin-right: 40px;

        &:last-child {
            margin-right: 0;
        }
    }

    .input-line {
        width: 782px;
    }

    /deep/ .el-form-item__content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .el-radio-group {
        width: 140px;
    }
}
</style>
